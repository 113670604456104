import React from 'react';
import { useState } from 'react';

// Custom SVG Icons
const BriefcaseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2M4 13.255V19a2 2 0 002 2h12a2 2 0 002-2v-5.745" />
  </svg>
);

const GraduationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l9-5-9-5-9 5 9 5z" />
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l9-5-9-5-9 5 9 5z" />
  </svg>
);

const LocationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>
);

const EmailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
  </svg>
);

const PhoneIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
  </svg>
);

const CodeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
  </svg>
);

const AwardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v1m6 11h2M6 11h2m6 0a2 2 0 100-4H6a2 2 0 000 4m6 0v1m-6-1v1m6 1v1m-6-1v1m16-4a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
);

const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
  </svg>
);

const ResumeWebsite = () => {
  const [activeSection, setActiveSection] = useState('about');

  const resumeData = {
    name: 'Samuel Theophilus',
    title: 'Cloud/DevOps Engineer',
    contact: {
      email: 'samueltheophilus019@gmail.com',
      phone: '+234 91 3737 1598',
      location: 'Kano, Nigeria',
      linkedin: 'linkedin.com/in/samuel-theophilus',
      github: 'github.com/afkzoro'
    },
    about: 'Passionate Cloud Engineer with expertise in GCP, DevOps, and modern cloud infrastructure. Skilled in containerization, CI/CD, and scalable system design.',
    skills: [
      'Google Cloud Platform (GCP)',
      'Kubernetes',
      'Docker',
      'Terraform',
      'CI/CD Pipelines',
      'Python',
      'Node.js',
      'Go'
    ],
    experience: [
      {
        company: 'TechInnovate Solutions',
        title: 'Senior Cloud Engineer',
        dates: 'Jan 2022 - Present',
        achievements: [
          'Designed and implemented scalable cloud infrastructure using GCP',
          'Reduced infrastructure costs by 30% through optimized resource allocation',
          'Led migration of monolithic applications to microservices architecture'
        ]
      },
      {
        company: 'CloudNative Systems',
        title: 'Cloud DevOps Engineer',
        dates: 'Jun 2019 - Dec 2021',
        achievements: [
          'Developed automated CI/CD pipelines using Jenkins and GitHub Actions',
          'Implemented comprehensive monitoring and logging solutions',
          'Conducted security audits and implemented best practices'
        ]
      }
    ],
    education: [
      {
        school: 'Stanford University',
        degree: 'Master of Science in Computer Science',
        dates: '2017 - 2019'
      },
      {
        school: 'UC Berkeley',
        degree: 'Bachelor of Science in Computer Engineering',
        dates: '2013 - 2017'
      }
    ],
    certifications: [
      'Google Cloud Professional Cloud Architect',
      'AWS Certified Solutions Architect',
      'Certified Kubernetes Administrator (CKA)'
    ]
  };

  const renderSection = () => {
    switch(activeSection) {
      case 'about':
        return (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <AwardIcon /> About Me
            </h2>
            <p className="text-gray-700">{resumeData.about}</p>
            
            <h3 className="text-xl font-semibold mt-6 flex items-center">
              <CodeIcon /> Technical Skills
            </h3>
            <div className="flex flex-wrap gap-2">
              {resumeData.skills.map((skill, index) => (
                <span 
                  key={index} 
                  className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>
        );
      case 'experience':
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <BriefcaseIcon /> Professional Experience
            </h2>
            {resumeData.experience.map((job, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg p-6">
                <h3 className="text-xl font-semibold">{job.title}</h3>
                <p className="text-gray-600">{job.company} | {job.dates}</p>
                <ul className="list-disc list-inside mt-3 text-gray-700">
                  {job.achievements.map((achievement, achIndex) => (
                    <li key={achIndex} className="mb-2">{achievement}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        );
      case 'education':
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <GraduationIcon /> Education
            </h2>
            {resumeData.education.map((edu, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg p-6">
                <h3 className="text-xl font-semibold">{edu.degree}</h3>
                <p className="text-gray-600">{edu.school} | {edu.dates}</p>
              </div>
            ))}
            
            <h3 className="text-xl font-semibold mt-6 flex items-center">
              <AwardIcon /> Certifications
            </h3>
            <div className="flex flex-wrap gap-2">
              {resumeData.certifications.map((cert, index) => (
                <span 
                  key={index} 
                  className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm"
                >
                  {cert}
                </span>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <div className="max-w-4xl w-full bg-white shadow-2xl rounded-xl overflow-hidden grid md:grid-cols-3">
        {/* Sidebar */}
        <div className="bg-blue-600 text-white p-8 md:col-span-1 flex flex-col">
          <div className="text-center mb-8">
            <div className="w-32 h-32 bg-white rounded-full mx-auto mb-4 overflow-hidden shadow-lg">
              <img 
                src="/api/placeholder/400/400" 
                alt="Profile" 
                className="w-full h-full object-cover"
              />
            </div>
            <h1 className="text-2xl font-bold">{resumeData.name}</h1>
            <p className="text-blue-200">{resumeData.title}</p>
          </div>

          <div className="space-y-4">
            <div className="flex items-center">
              <EmailIcon />
              <a href={`mailto:${resumeData.contact.email}`} className="hover:underline">
                {resumeData.contact.email}
              </a>
            </div>
            <div className="flex items-center">
              <PhoneIcon />
              {resumeData.contact.phone}
            </div>
            <div className="flex items-center">
              <LocationIcon />
              {resumeData.contact.location}
            </div>
            <div className="flex items-center">
              <LinkIcon />
              <a 
                href={`https://${resumeData.contact.linkedin}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="hover:underline"
              >
                LinkedIn
              </a>
            </div>
            <div className="flex items-center">
              <CodeIcon />
              <a 
                href={`https://${resumeData.contact.github}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="hover:underline"
              >
                GitHub
              </a>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="p-8 md:col-span-2">
          {/* Navigation */}
          <div className="flex border-b mb-8">
            {['about', 'experience', 'education'].map((section) => (
              <button
                key={section}
                className={`px-4 py-2 capitalize 
                  ${activeSection === section 
                    ? 'border-b-2 border-blue-600 text-blue-600 font-semibold' 
                    : 'text-gray-500 hover:text-blue-600'}`}
                onClick={() => setActiveSection(section)}
              >
                {section}
              </button>
            ))}
          </div>

          {/* Dynamic Content */}
          {renderSection()}
        </div>
      </div>
    </div>
  );
};

export default ResumeWebsite;